//to handle state
const state = {
    contributions_detail: [],
    total:0,
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadAddContributionDetail(context,contribution) {
        let validate = true;
        let detail = context.state.contributions_detail;
        for (let index = 0; index < detail.length; index++) {
            if (detail[index].year == contribution.year && detail[index].month == contribution.month) {
                validate = false;
                break;
            }
        }
        if (validate) {
            context.commit('mAddContributionDetail',contribution);
        }
        context.dispatch('mLoadTotalContributionDetail');
    },

    mLoadEditContributionDetail(context,index){
        let detail = context.state.contributions_detail;
        detail[index].contribution = detail[index].contribution.length == 0 ? 0 : parseFloat(detail[index].contribution);
        detail[index].contribution = parseFloat(detail[index].contribution).toFixed(2);

        detail[index].burial = detail[index].burial.length == 0 ? 0 : parseFloat(detail[index].burial);
        detail[index].burial = parseFloat(detail[index].burial).toFixed(2);

        detail[index].total = parseFloat(detail[index].contribution) + parseFloat(detail[index].burial);
        detail[index].total = parseFloat(detail[index].total).toFixed(2);

        context.dispatch('mLoadTotalContributionDetail');
    },

    mLoadDeleteContributionDetail(context,index){
        context.commit('mDeleteContributionDetail',index);
        context.dispatch('mLoadTotalContributionDetail');
    },

    mLoadResetContributionDetail(context){
        context.commit('mResertContributionDetail');
        context.dispatch('mLoadTotalContributionDetail');
    },

    mLoadTotalContributionDetail(context){
        let detail = context.state.contributions_detail;
        let total = 0;
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            total += parseFloat(element.contribution);
            total += parseFloat(element.burial);
        }
        total = parseFloat(total).toFixed(2);
        context.commit('mTotalContributionDetail',total);
        context.state.contributions_detail.sort();
    },
}

//to handle mutations
const mutations = {

    mAddContributionDetail(state, detail) {
        state.contributions_detail.push(detail);
    },

    mDeleteContributionDetail(state, index) {
        state.contributions_detail.splice(index,1);
    },
    mResertContributionDetail(state) {
        state.contributions_detail = [];
    },
    mTotalContributionDetail(state,total) {
        state.total = total;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}